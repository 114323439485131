<!--
File: WorkCategoriesDropdown.vue
Description: show the dropdown combo with the Work Categories list in the TreatmentsTypes.vue and TreatmentTypeEditForm.vue.
-->
<template>
  <md-field>
    <label for="work_category_id">{{ label }}</label>
    <md-select :required="is_required" v-model='selectedValue' name="work_category_id" id="work_category_id"
      @md-selected='onChange' :disabled="disabled">
      <md-option v-for='cat in categoriesList' :key='cat.id' :value='cat.id'>
        {{ cat.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'work-categories-dropdown',

    props: {
      label: { default: null, type: String },
      value: { default: null, type: [String, Number, Array] },  // Добавляем поддержку v-model через пропс value
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        selectedValue: this.value || null,  // Инициализируем selectedValue из пропса value
      }
    },

    mounted() {
      this.$store.dispatch('GET_WORK_CATEGORIES_LIST', !this.is_required).then(() => {
        if (this.value && !this.selectedValue) this.selectedValue = this.value;
      })
    },

    methods: {
      onChange() {
        this.$emit('input', this.selectedValue)
      },
    },

    computed: {
      categoriesList() {
        return this.$store.state.Dropdowns.work_categories
      }
    },

    watch: {
      value(newValue) { // Следим за изменениями value (v-model)
        if (newValue !== this.selectedValue) {
          this.selectedValue = newValue;
        }
      },
    }

  }
</script>